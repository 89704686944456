import PasswordStrength from "../../../ui/password-strength/PasswordStrength";
import { STATIC_STRINGS } from "../../../../redux/static/staticTexts";
import Input from "../../../ui/inputs/Input";
import { useCallback } from "react";
import { ReactComponent as ShowPasswordIcon } from "./../../../../assets/icons/eye.svg";
import { ReactComponent as HidePasswordIcon } from "./../../../../assets/icons/eye-closed.svg";
import InputInlineError from "../../../ui/inputs/inline-error/Input.inline-error";
import "./password.scss";
import { Action, ISetValueAction } from "../state";

const minRegexPassword =
  "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})";

export const goodRegEx = new RegExp(minRegexPassword);

export interface IStepPassword {
  state: ISetValueAction;
  dispatch: (param: Action) => void;
  requestSubmit: () => void;
  arePassEqual: boolean;
  passMeetsMinScore: boolean;
  checkPassError: (key: keyof ISetValueAction) => {
    isValid: boolean;
    message: string;
  };
}

export default function StepPassword({
  state,
  dispatch,
  requestSubmit,
  arePassEqual,
  passMeetsMinScore,
  checkPassError,
}: IStepPassword) {
  const cb = useCallback(
    (e: number) => {
      if (e === state.passwordStrength) {
        return;
      }

      dispatch({
        type: "setPassStrength",
        payload: e,
      });
    },
    [dispatch, state.passwordStrength]
  );

  return (
    <>
      <p className="register-form__intro">
        To complete your account set up, create a password containing at least:
      </p>

      <ul className="register-form__list list list--standard">
        <li>
          <strong>1 Uppercase Letter</strong>
        </li>

        <li>
          <strong>1 Lowercase Letter</strong>
        </li>

        <li>
          <strong>1 Special Character @ # / ! ? ; '</strong>
        </li>

        <li>
          <strong>1 Number</strong>
        </li>

        <li>
          At least <strong>Medium</strong> strength
        </li>
      </ul>

      <Input
        autocomplete={false}
        type={state.passwordType}
        error={!checkPassError("password").isValid || !passMeetsMinScore}
        id={"login__password"}
        placeholder={STATIC_STRINGS.SIGN_IN.FORM.PASSWORD_PLACEHOLDER}
        value={state.password}
        requestInput={(e) =>
          dispatch({
            type: "setPassword",
            payload: e,
          })
        }
      >
        <button
          type="button"
          className="btn--reset sign-in__show-pass-btn"
          onClick={() =>
            dispatch({
              type: "setPasswordType",
              payload: state.passwordType === "password" ? "text" : "password",
            })
          }
        >
          {state.passwordType === "password" ? (
            <ShowPasswordIcon />
          ) : (
            <HidePasswordIcon />
          )}
        </button>

        <InputInlineError
          isVisible={
            (!checkPassError("password").isValid &&
              checkPassError("password").message.length > 0) ||
            (state.password.length > 0 && !passMeetsMinScore)
          }
          message={
            checkPassError("password").message ||
            "" ||
            (state.password.length > 0 && !passMeetsMinScore
              ? "Password does not meet required strength"
              : "")
          }
        />
      </Input>

      <Input
        autocomplete={false}
        type={state.rePasswordType}
        error={!checkPassError("rePassword").isValid || !arePassEqual}
        id={"re_login__password"}
        placeholder={STATIC_STRINGS.SIGN_IN.FORM.PASSWORD_RETYPE_PLACEHOLDER}
        value={state.rePassword}
        requestInput={(e) =>
          dispatch({
            type: "setRePassword",
            payload: e,
          })
        }
      >
        <button
          type="button"
          className="btn--reset sign-in__show-pass-btn"
          onClick={() =>
            dispatch({
              type: "setRePasswordType",
              payload:
                state.rePasswordType === "password" ? "text" : "password",
            })
          }
        >
          {state.rePasswordType === "password" ? (
            <ShowPasswordIcon />
          ) : (
            <HidePasswordIcon />
          )}
        </button>

        <InputInlineError
          isVisible={
            (!checkPassError("rePassword").isValid &&
              checkPassError("rePassword").message.length > 0) ||
            !arePassEqual
          }
          message={
            checkPassError("rePassword").message ||
            "" ||
            (!arePassEqual ? "Passwords do not match" : "")
          }
        />
      </Input>

      <PasswordStrength
        value={!checkPassError("password").isValid ? "" : state.password}
        requestStrengthUpdate={cb}
      />

      <div className="set-pass__actions">
        <button
          type="button"
          onClick={requestSubmit}
          disabled={
            !arePassEqual ||
            !checkPassError("password").isValid ||
            !checkPassError("rePassword").isValid ||
            !passMeetsMinScore
          }
          className="btn btn--default btn--short btn--long"
        >
          Next Step
        </button>
      </div>
    </>
  );
}
