import "./history.scss";
import { ReactComponent as SortArrowIcon } from "../../assets/icons/arrow-down-long.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import HistorySearchForm from "./search/historySearchForm";
import HistoryDatesForm from "./date-picker/historyDatesForm";
import InlineLoader from "../../components/ui/inline-loader/InlineLoader";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectCurrentPayee } from "../../redux/user/selectors";
import { useQueryVoucherHistory } from "../../redux/hooks/useQueryVoucherHistory";
import { startAddNotification } from "../../redux/core/core";
import { INotificationType } from "../../components/ui/notifications/item/NotificationItem";
import CheckBox from "../../components/ui/checkbox/checkbox";
import { MouseEvent } from "react";
import HistoryPagination from "./pagination/pagination";
import AdvancedSearch from "./advanced-search/advanced-search";
import HistoryMultiSelect from "./history-multiselect/history-multiselect";
import moment from "moment";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as CheckedIcon } from "../../assets/icons/checked.svg";
import { useMutationExportVoucherHistory } from "../../redux/hooks/useMutationExportVoucherHistory";

export const HISTORY_RESULTS_PER_PAGE = 10;

export const INVOICE_STATUS = {
  PAID: "Paid",
  AUTHORISED: "Authorised",
  HOLD: "Hold for client payment",
};

export const INVOICE_STATUS_DEFAULT_OPTIONS = [
  {
    name: INVOICE_STATUS.PAID,
    isSelected: false,
  },
  {
    name: INVOICE_STATUS.AUTHORISED,
    isSelected: false,
  },
  {
    name: INVOICE_STATUS.HOLD,
    isSelected: false,
  },
];

export const CURRENCY_SELECT = {
  GBP: "GBP",
  USD: "USD",
  EUR: "EUR",
};

export const DEFAULT_SELECTIONS = [
  {
    name: CURRENCY_SELECT.USD,
    isSelected: false,
  },
  {
    name: CURRENCY_SELECT.GBP,
    isSelected: false,
  },
  {
    name: CURRENCY_SELECT.EUR,
    isSelected: false,
  },
];

export enum IHistorySortOptions {
  ASC = "asc",
  DESC = "desc",
}

export enum IHistorySortCol {
  INVOICE_NO = "invoice_no",
  DATE = "date",
  AMOUNT = "amount",
  INVOICE_STATUS = "status",
  CURRENCY = "currency",
}

export interface IHistoryQueryFilters {
  page: number;
  fromDate: string;
  toDate: string;
  searchTerm: string;
  currencies: string;
  statuses: string;
  payeeNumber: string;
}

export const IHistoryQueryInitialState: IHistoryQueryFilters = {
  page: 1,
  fromDate: "",
  toDate: "",
  searchTerm: "",
  currencies: "",
  statuses: "",
  payeeNumber: "",
};

export default function History() {
  const [activeSortOption, setActiveSortOption] = useState<IHistorySortCol>(
    IHistorySortCol.INVOICE_NO
  );
  const [sortDirection, setSortDirection] = useState<IHistorySortOptions>(
    IHistorySortOptions.ASC
  );

  const [areAllChecked, setAreAllChecked] = useState(false);

  const [advSearchCollapsed, setAdvSearchCollapsed] = useState(false);

  const [historySearchTerm, setHistorySearchTerm] = useState("");
  const [currencySelections, setCurrencySelections] =
    useState(DEFAULT_SELECTIONS);
  const [statusSelections, setStatusSelection] = useState(
    INVOICE_STATUS_DEFAULT_OPTIONS
  );
  const [fromDate, setFromDate] = useState<null | moment.Moment>(null);
  const [toDate, setToDate] = useState<null | moment.Moment>(null);
  const dispatchStore = useAppDispatch();

  const [mobileInvoiceIdCollapsed, setMobileInvoiceIdCollapsed] = useState<
    string | null
  >(null);

  const payee = useAppSelector(selectCurrentPayee);

  const [queryFilters, setQueryFilters] = useState<IHistoryQueryFilters>({
    ...IHistoryQueryInitialState,
  });

  const [checkedItems, setCheckedItems] = useState<string[]>();

  const isItemChecked = useCallback(
    (invNumber: string) => {
      if (!checkedItems || !checkedItems.length) {
        return false;
      }

      return checkedItems.includes(invNumber);
    },
    [checkedItems]
  );

  const areFiltersSet = useMemo(() => {
    if (!queryFilters) {
      return false;
    }

    return (
      queryFilters.fromDate.length > 0 ||
      queryFilters.toDate.length > 0 ||
      queryFilters.statuses.length > 0 ||
      queryFilters.currencies.length > 0 ||
      queryFilters.searchTerm.length > 0
    );
  }, [queryFilters]);

  const { isLoading, isError, data, isRefetching } = useQueryVoucherHistory({
    ...queryFilters,
    condition: queryFilters.payeeNumber.length > 0,
  });

  const { mutate } = useMutationExportVoucherHistory();

  const items = useMemo(() => {
    if (!data) {
      return [];
    }

    const currentItems = data.data.data.data.map((item) => ({ ...item }));

    switch (activeSortOption) {
      case IHistorySortCol.INVOICE_NO:
        return currentItems.sort((a, b) => {
          if (sortDirection !== IHistorySortOptions.ASC) {
            return a.invNumber.localeCompare(b.invNumber);
          }

          return b.invNumber.localeCompare(a.invNumber);
        });

      case IHistorySortCol.AMOUNT:
        return currentItems.sort((a, b) => {
          if (sortDirection === IHistorySortOptions.ASC) {
            return (
              parseFloat(a.amount.toString().replace(",", "")) -
              parseFloat(b.amount.toString().replace(",", ""))
            );
          }

          return (
            parseFloat(b.amount.toString().replace(",", "")) -
            parseFloat(a.amount.toString().replace(",", ""))
          );
        });

      case IHistorySortCol.DATE:
        return currentItems.sort((a, b) => {
          if (sortDirection === IHistorySortOptions.ASC) {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
          }

          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });

      case IHistorySortCol.CURRENCY:
        return currentItems.sort((a, b) => {
          if (sortDirection === IHistorySortOptions.ASC) {
            return a.currency.localeCompare(b.currency);
          }

          return b.currency.localeCompare(a.currency);
        });

      case IHistorySortCol.INVOICE_STATUS:
        return currentItems.sort((a, b) => {
          if (sortDirection === IHistorySortOptions.ASC) {
            return a.status.localeCompare(b.status);
          }

          return b.status.localeCompare(a.status);
        });

      default:
        return currentItems;
    }
  }, [activeSortOption, data, sortDirection]);

  const areFiltersChanged = useMemo(() => {
    const sameSearch =
      queryFilters.searchTerm.toLowerCase().trim() !==
      historySearchTerm.trim().toLowerCase();
    const sameFromDate =
      fromDate !== null
        ? fromDate.format("YYYY-MM-DD") !== queryFilters.fromDate
        : false;
    const sameToDate =
      toDate !== null
        ? toDate.format("YYYY-MM-DD") !== queryFilters.toDate
        : false;
    const sameCurrencies = !currencySelections
      .filter((item) => item.isSelected)
      .every((item) => queryFilters.currencies.includes(item.name));
    const sameStatuses = !statusSelections
      .filter((item) => item.isSelected)
      .every((item) => queryFilters.statuses.includes(item.name));

    return (
      sameSearch || sameFromDate || sameToDate || sameCurrencies || sameStatuses
    );
  }, [
    currencySelections,
    fromDate,
    historySearchTerm,
    queryFilters.currencies,
    queryFilters.fromDate,
    queryFilters.searchTerm,
    queryFilters.statuses,
    queryFilters.toDate,
    statusSelections,
    toDate,
  ]);

  useEffect(() => {
    if (items.length > 0) {
      setAreAllChecked(areFiltersSet);
    }
  }, [areFiltersSet, items, items.length]);

  useEffect(() => {
    if (payee) {
      setQueryFilters((state) => ({
        ...state,
        payeeNumber: payee.payeeNumber,
      }));
    }
  }, [payee]);

  useEffect(() => {
    if (isError) {
      dispatchStore(
        startAddNotification({
          title: "Error",
          description: `Unknown voucher history api error`,
          type: INotificationType.ERROR,
        })
      );
    }
  }, [dispatchStore, isError]);

  return (
    <div className="history">
      <header>
        <h2 className="history__title history__title--subtitle">
          Payee Account: {payee ? payee.name : ""}
        </h2>

        <h1 className="history__title">Invoice History</h1>

        <div className="history__search-fields-container">
          <HistorySearchForm
            isDisabled={isLoading}
            value={historySearchTerm}
            requestSubmit={() => {
              const currencies = currencySelections.some(
                (item) => item.isSelected
              )
                ? currencySelections
                    .filter((item) => item.isSelected)
                    .map((item) => item.name)
                    .join(",")
                : "";
              const statuses = statusSelections.some((item) => item.isSelected)
                ? statusSelections
                    .filter((item) => item.isSelected)
                    .map((item) => item.name)
                    .join(",")
                : "";

              setQueryFilters({
                ...queryFilters,
                page: 1,
                statuses,
                currencies,
                fromDate: fromDate ? fromDate.format("YYYY-MM-DD") : "",
                toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
                searchTerm: historySearchTerm.trim(),
              });
            }}
            requestChange={setHistorySearchTerm}
          />

          <AdvancedSearch
            requestCollapse={setAdvSearchCollapsed}
            isCollapsed={advSearchCollapsed}
          />

          <button
            type="button"
            className={`btn--reset history-clear-filters ${
              areFiltersSet ? "history-clear-filters--active" : ""
            }`}
            title="Clear Filters"
            onClick={() => {
              setHistorySearchTerm("");
              setToDate(null);
              setFromDate(null);
              setCurrencySelections(
                currencySelections.map((item) => ({
                  ...item,
                  isSelected: false,
                }))
              );
              setStatusSelection(
                statusSelections.map((item) => ({
                  ...item,
                  isSelected: false,
                }))
              );

              return setQueryFilters(() => ({
                ...IHistoryQueryInitialState,
                payeeNumber: queryFilters.payeeNumber,
              }));
            }}
          >
            <TrashIcon />

            <span className="history-clear-filters__content">
              Clear Filters
            </span>
          </button>

          <button
            type="button"
            className="btn excel-btn"
            disabled={
              data?.data.data.totalResults === 0 ||
              (!areAllChecked && (!checkedItems || checkedItems.length === 0))
            }
            onClick={handleExport}
          >
            Export to Excel
          </button>
        </div>

        <div
          className={`adv-search-container ${
            advSearchCollapsed ? "adv-search-container--opened" : ""
          }`}
        >
          <HistoryDatesForm
            setFromDate={(event) => {
              if (event === null && queryFilters.fromDate.length > 0) {
                setQueryFilters({
                  ...queryFilters,
                  fromDate: "",
                });
              }

              setFromDate(event);

              if (toDate !== null) {
                return;
              }

              setToDate(event);
            }}
            setToDate={(event) => {
              if (event === null && queryFilters.toDate.length > 0) {
                setQueryFilters({
                  ...queryFilters,
                  toDate: "",
                });
              }

              setToDate(event);

              if (fromDate !== null) {
                return;
              }

              setFromDate(event);
            }}
            fromDate={fromDate}
            toDate={toDate}
          />

          <HistoryMultiSelect
            label={"Currency"}
            placeholder={"All Currencies"}
            requestChange={(event) => {
              setCurrencySelections(
                currencySelections.map((item) => ({
                  ...item,
                  isSelected:
                    item.name === event.name
                      ? event.isSelected || false
                      : item.isSelected,
                }))
              );
            }}
            requestClearAll={() => {
              setQueryFilters({
                ...queryFilters,
                currencies: "",
              });

              setCurrencySelections(
                currencySelections.map((item) => ({
                  ...item,
                  isSelected: false,
                }))
              );
            }}
            options={currencySelections}
          />

          <HistoryMultiSelect
            label="Invoice Status"
            requestChange={(event) => {
              setStatusSelection(
                statusSelections.map((item) => ({
                  ...item,
                  isSelected:
                    item.name === event.name
                      ? event.isSelected || false
                      : item.isSelected,
                }))
              );
            }}
            requestClearAll={() => {
              setQueryFilters({
                ...queryFilters,
                statuses: "",
              });

              setStatusSelection(
                statusSelections.map((item) => ({
                  ...item,
                  isSelected: false,
                }))
              );
            }}
            placeholder={"Status"}
            options={statusSelections}
          />

          <button
            type="button"
            className={`history-apply-filters btn ${
              areFiltersChanged ? "history-apply-filters--active" : ""
            }`}
            onClick={() => {
              const currencies = currencySelections.some(
                (item) => item.isSelected
              )
                ? currencySelections
                    .filter((item) => item.isSelected)
                    .map((item) => item.name)
                    .join(",")
                : "";
              const statuses = statusSelections.some((item) => item.isSelected)
                ? statusSelections
                    .filter((item) => item.isSelected)
                    .map((item) => item.name)
                    .join(",")
                : "";

              setQueryFilters({
                ...queryFilters,
                page: 1,
                statuses,
                currencies,
                fromDate: fromDate ? fromDate.format("YYYY-MM-DD") : "",
                toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
                searchTerm: historySearchTerm,
              });
            }}
          >
            <CheckedIcon /> Apply Filters
          </button>
        </div>
      </header>

      {isLoading && (
        <div className="history__loader">
          <InlineLoader />
        </div>
      )}

      {!isLoading && (
        <div className="overflow-auto">
          <div className="mobile-table-head">
            <CheckBox
              id={"mobile-users"}
              checked={areAllChecked}
              value={""}
              customClass={"history__table--actions-checkbox"}
              requestChecked={(event) => {
                if (checkedItems && checkedItems.length) {
                  setCheckedItems([]);
                }

                setAreAllChecked(event);
              }}
            />

            <span className="mobile-table-head__content">
              Select All Invoices
            </span>
          </div>

          <table cellPadding={0} cellSpacing={0} className="history__table">
            <thead className="history__thead">
              <tr className="history__tr">
                <th className="history__cell">
                  <div className="d-flex history__table--actions">
                    <CheckBox
                      id={"ms-users"}
                      checked={areAllChecked}
                      value={""}
                      customClass={"history__table--actions-checkbox"}
                      requestChecked={(event) => {
                        if (checkedItems && checkedItems.length) {
                          setCheckedItems([]);
                        }

                        setAreAllChecked(event);
                      }}
                    />

                    <button
                      type="button"
                      className="btn history-sort-btn"
                      onClick={activateSort}
                      data-sort-col={IHistorySortCol.INVOICE_NO}
                      title="Sort by invoice number"
                    >
                      <span className="visually-hidden">
                        Sort by invoice number
                      </span>
                      Invoice No.
                      <SortArrowIcon
                        width="5"
                        height="12"
                        className={
                          "is-" +
                          (activeSortOption === IHistorySortCol.INVOICE_NO &&
                          sortDirection === IHistorySortOptions.ASC
                            ? "ascending"
                            : "descending")
                        }
                      />
                    </button>
                  </div>
                </th>

                <th className="history__cell">
                  <button
                    type="button"
                    className="btn history-sort-btn"
                    onClick={activateSort}
                    data-sort-col={IHistorySortCol.DATE}
                    title="Sort by invoice date"
                  >
                    <span className="visually-hidden">
                      Sort by invoice date
                    </span>
                    Date
                    <SortArrowIcon
                      width="5"
                      height="12"
                      className={
                        "is-" +
                        (activeSortOption === IHistorySortCol.DATE &&
                        sortDirection === IHistorySortOptions.ASC
                          ? "ascending"
                          : "descending")
                      }
                    />
                  </button>
                </th>

                <th className="history__cell">
                  <button
                    type="button"
                    className="btn history-sort-btn"
                    onClick={activateSort}
                    data-sort-col={IHistorySortCol.AMOUNT}
                    title="Sort by invoice amount"
                  >
                    <span className="visually-hidden">
                      Sort by invoice amount
                    </span>
                    Amount
                    <SortArrowIcon
                      width="5"
                      height="12"
                      className={
                        "is-" +
                        (activeSortOption === IHistorySortCol.AMOUNT &&
                        sortDirection === IHistorySortOptions.ASC
                          ? "ascending"
                          : "descending")
                      }
                    />
                  </button>
                </th>

                <th className="history__cell">
                  <button
                    type="button"
                    className="btn history-sort-btn"
                    onClick={activateSort}
                    data-sort-col={IHistorySortCol.INVOICE_STATUS}
                    title="Sort by invoice status"
                  >
                    <span className="visually-hidden">
                      Sort by invoice status
                    </span>
                    Invoice Status
                    <SortArrowIcon
                      width="5"
                      height="12"
                      className={
                        "is-" +
                        (activeSortOption === IHistorySortCol.INVOICE_STATUS &&
                        sortDirection === IHistorySortOptions.ASC
                          ? "ascending"
                          : "descending")
                      }
                    />
                  </button>
                </th>

                <th className="history__cell">
                  <button
                    type="button"
                    className="btn history-sort-btn"
                    onClick={activateSort}
                    data-sort-col={IHistorySortCol.CURRENCY}
                    title="Sort by invoice currency"
                  >
                    <span className="visually-hidden">
                      Sort by invoice currency
                    </span>
                    Currency
                    <SortArrowIcon
                      width="5"
                      height="12"
                      className={
                        "is-" +
                        (activeSortOption === IHistorySortCol.CURRENCY &&
                        sortDirection === IHistorySortOptions.ASC
                          ? "ascending"
                          : "descending")
                      }
                    />
                  </button>
                </th>
              </tr>
            </thead>
            <tbody className="history__tbody">
              {items.length > 0 ? (
                items.map((invoice) => (
                  <tr
                    className={`history__tr ${
                      mobileInvoiceIdCollapsed === invoice.invNumber
                        ? "history__tr--collapsed"
                        : ""
                    }`}
                    key={invoice.invNumber}
                    onClick={() => {
                      return setMobileInvoiceIdCollapsed(
                        invoice.invNumber === mobileInvoiceIdCollapsed
                          ? null
                          : invoice.invNumber
                      );
                    }}
                  >
                    <td className="history__cell history__cell--invoice-number">
                      <div className="d-flex history__table--actions">
                        <CheckBox
                          id={`ms-users-${invoice.invNumber}`}
                          checked={
                            areAllChecked || isItemChecked(invoice.invNumber)
                          }
                          value={""}
                          customClass={"history__table--actions-cell-checkbox"}
                          requestChecked={() => {
                            if (areAllChecked) {
                              setAreAllChecked(false);

                              const currentItems = data
                                ? data.data.data.data
                                    .filter(
                                      (item) =>
                                        item.invNumber !== invoice.invNumber
                                    )
                                    .map((item) => item.invNumber)
                                : [];

                              return setCheckedItems(currentItems);
                            }

                            if (isItemChecked(invoice.invNumber)) {
                              return setCheckedItems(
                                checkedItems
                                  ? checkedItems
                                      .map((item) => item)
                                      .filter(
                                        (item) => item !== invoice.invNumber
                                      )
                                  : []
                              );
                            }

                            const currentItems = checkedItems
                              ? checkedItems.map((item) => item)
                              : [];

                            currentItems.push(invoice.invNumber);

                            return setCheckedItems(currentItems);
                          }}
                        />

                        <div
                          className="ellipsis-text"
                          title={invoice.invNumber}
                        >
                          {invoice.invNumber}
                        </div>
                      </div>
                    </td>
                    <td
                      data-cell-name="Date"
                      className="history__cell history__cell--date"
                    >
                      {invoice.date}
                    </td>
                    <td
                      data-cell-name="Amount"
                      className="history__cell history__cell--amount"
                    >
                      {invoice.amount}
                    </td>
                    <td
                      data-cell-name="Status"
                      className="history__cell history__cell--status"
                    >
                      <span
                        data-cell-status={invoice.status.trim().toLowerCase()}
                      >
                        {invoice.status}
                      </span>
                    </td>
                    <td
                      data-cell-name="Currency"
                      className="history__cell history__cell--matter-number"
                    >
                      {invoice.currency}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>
                    <p className="history-no-results">
                      {areFiltersSet && !isRefetching
                        ? "No results found for current search criteria"
                        : "No data available"}
                    </p>

                    {isRefetching && (
                      <div className="d-flex justify-content-center">
                        <InlineLoader />
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {items.length > 0 && (
            <HistoryPagination
              disabled={isLoading}
              totalCount={data ? data.data.data.totalResults : 0}
              pageSize={HISTORY_RESULTS_PER_PAGE}
              siblingCount={1}
              currentPage={data ? data.data.data.activePage : 0}
              requestClick={(event) => {
                setQueryFilters({
                  ...queryFilters,
                  page: event,
                });
              }}
            />
          )}
        </div>
      )}
    </div>
  );

  function activateSort(event: MouseEvent<HTMLButtonElement>) {
    if (!event) {
      return;
    }

    event.stopPropagation();

    const activeSortCol = (event.target as HTMLButtonElement).getAttribute(
      "data-sort-col"
    ) as IHistorySortCol;

    if (!activeSortCol) {
      return;
    }

    setActiveSortOption(activeSortCol);
    setSortDirection(
      sortDirection === IHistorySortOptions.ASC
        ? IHistorySortOptions.DESC
        : IHistorySortOptions.ASC
    );
  }

  function handleExport() {
    const items = areAllChecked
      ? ["*"]
      : checkedItems
      ? checkedItems.map((item) => item)
      : [];

    return mutate({
      fromDate: queryFilters.fromDate,
      toDate: queryFilters.toDate,
      payeeNumber: queryFilters.payeeNumber,
      currencies: queryFilters.currencies,
      statuses: queryFilters.statuses,
      searchTerm: queryFilters.searchTerm,
      items,
    });
  }
}
