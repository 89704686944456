import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  isAdminAccount,
  isSupervisorAccount,
  selectCurrentPayee,
} from "../../redux/user/selectors";
import { useQueryBankDetails } from "../../redux/hooks/useQueryBankDetails";
import { useEffect, useState } from "react";
import { startAddNotification } from "../../redux/core/core";
import { INotificationType } from "../../components/ui/notifications/item/NotificationItem";
import InlineLoader from "../../components/ui/inline-loader/InlineLoader";
import GenericNotFound from "../../components/ui/generic-not-found/GenericNotFound";
import "./bank-details.scss";
import BankDetailItem, {
  IBankDetailSavePayload,
} from "./item/bank-detail-item";
import BankDetailItemAlt, {
  IBankDetailAltSaveItem,
} from "./item-alt/bank-detail-item-alt";
import {
  ISendBankDetailType,
  sendBankDetails,
} from "../../redux/user/saga/vendor/send-bank-details";
import BankDetailItemModal from "./item/modal/bank-detail-item-modal";
import { IProfilePasswordModalAction } from "../users/types";
import BankDetailItemModalAlt from "./item-alt/modal/bank-detail-item-modal-alt";

const initialUkBankDetails = {
  bankAccount: "",
  bankCode: "",
  accountName: "",
  toDate: null,
  id: "",
  bankName: "",
  startDate: "",
  bankAccountType: "",
};

const initialIntBankDetails = {
  iban: "",
  accountName: "",
  toDate: null,
  id: "",
  bic: "",
  bankName: "",
  startDate: "",
  detailsOfCharges: "",
  intBic: "",
  endDate: "",
  bankAccountType: "",
};

export enum IBankAccountType {
  UK = "UKDomestic",
  INT = "International",
  INT_OTHER = "InternationalInt",
}

export default function BankDetails() {
  const dispatchStore = useAppDispatch();

  const payee = useAppSelector(selectCurrentPayee);

  const [isSendingUpdate, setIsSendingUpdate] = useState(false);

  const [isUkModalVisible, setUkModalVisible] = useState(false);
  const [isIntModalVisible, setIntModalVisible] = useState(false);

  const { isError, isLoading, data } = useQueryBankDetails({
    payeeNumber: payee ? payee.payeeNumber : "",
    condition: payee !== undefined,
  });

  const [ukBankDetails, setUkBankDetails] = useState({
    ...initialUkBankDetails,
  });

  const [intBankDetails, setIntBankDetails] = useState({
    ...initialIntBankDetails,
  });

  const isSupervisorAccountState = useAppSelector(isSupervisorAccount);
  const isAdminAccountState = useAppSelector(isAdminAccount);

  useEffect(() => {
    if (isError) {
      dispatchStore(
        startAddNotification({
          title: "Error",
          description: `Unknown voucher history api error`,
          type: INotificationType.ERROR,
        })
      );
    }
  }, [dispatchStore, isError]);

  useEffect(() => {
    if (!isLoading && data) {
      if (data.data.data[0]) {
        setUkBankDetails(data.data.data[0] as any);
      }

      if (data.data.data[0]) {
        setIntBankDetails(data.data.data[0] as any);
      }
    }
  }, [data, isLoading]);

  return (
    <div className="history">
      <header>
        <h2 className="history__title history__title--subtitle">
          Payee Account: {payee ? payee.name : ""}
        </h2>

        <h1 className="history__title">Bank Details</h1>
      </header>

      {isLoading && (
        <div className="history__loader">
          <InlineLoader />
        </div>
      )}

      {!isLoading && isError && (
        <div className="d-flex justify-content-center">
          <GenericNotFound hideLogo />
        </div>
      )}

      <div className="bank-details">
        <p>
          You can advise CMS of new details. These will be validated by the CMS
          team for security purposes prior to updating our records.
        </p>
        <p>You will be notified via the email when this is completed.</p>

        <div className="bank-cards">
          <BankDetailItem
            isEditable={isAdminAccountState || isSupervisorAccountState}
            isLoading={isSendingUpdate || isLoading}
            accountName={
              ukBankDetails.bankAccountType === IBankAccountType.UK
                ? ukBankDetails.bankName
                : ""
            }
            accountNumber={
              ukBankDetails.bankAccountType === IBankAccountType.UK
                ? ukBankDetails.bankAccount
                : ""
            }
            startDate={
              ukBankDetails.bankAccountType === IBankAccountType.UK
                ? ukBankDetails.startDate
                : ""
            }
            sortCode={
              ukBankDetails.bankAccountType === IBankAccountType.UK
                ? ukBankDetails.bankCode
                : ""
            }
            requestChange={(type) => {
              setUkModalVisible(true);
            }}
          />

          <BankDetailItemAlt
            isEditable={isAdminAccountState || isSupervisorAccountState}
            isLoading={isSendingUpdate || isLoading}
            iban={
              intBankDetails.bankAccountType === IBankAccountType.INT ||
              intBankDetails.bankAccountType === IBankAccountType.INT_OTHER
                ? intBankDetails.iban
                : ""
            }
            startDate={
              intBankDetails.bankAccountType === IBankAccountType.INT ||
              intBankDetails.bankAccountType === IBankAccountType.INT_OTHER
                ? intBankDetails.startDate
                : ""
            }
            accountName={
              intBankDetails.bankAccountType === IBankAccountType.INT ||
              intBankDetails.bankAccountType === IBankAccountType.INT_OTHER
                ? intBankDetails.bankName
                : ""
            }
            bic={
              intBankDetails.bankAccountType === IBankAccountType.INT ||
              intBankDetails.bankAccountType === IBankAccountType.INT_OTHER
                ? intBankDetails.bic
                : ""
            }
            detailsOfCharges={
              intBankDetails.bankAccountType === IBankAccountType.INT ||
              intBankDetails.bankAccountType === IBankAccountType.INT_OTHER
                ? intBankDetails.detailsOfCharges
                : ""
            }
            intBic={
              intBankDetails.bankAccountType === IBankAccountType.INT ||
              intBankDetails.bankAccountType === IBankAccountType.INT_OTHER
                ? intBankDetails.intBic
                : ""
            }
            endDate={
              intBankDetails.bankAccountType === IBankAccountType.INT ||
              intBankDetails.bankAccountType === IBankAccountType.INT_OTHER
                ? intBankDetails.endDate
                : ""
            }
            requestChange={(type) => {
              setIntModalVisible(true);
            }}
          />

          <BankDetailItemModalAlt
            isVisible={isIntModalVisible}
            existingData={{
              iban:
                intBankDetails.bankAccountType === IBankAccountType.INT ||
                intBankDetails.bankAccountType === IBankAccountType.INT_OTHER
                  ? intBankDetails.iban
                  : "",
              accountName:
                intBankDetails.bankAccountType === IBankAccountType.INT ||
                intBankDetails.bankAccountType === IBankAccountType.INT_OTHER
                  ? intBankDetails.bankName
                  : "",
              startDate:
                intBankDetails.bankAccountType === IBankAccountType.INT ||
                intBankDetails.bankAccountType === IBankAccountType.INT_OTHER
                  ? intBankDetails.startDate
                  : "",
              bic:
                intBankDetails.bankAccountType === IBankAccountType.INT ||
                intBankDetails.bankAccountType === IBankAccountType.INT_OTHER
                  ? intBankDetails.bic
                  : "",
              detailsOfCharges:
                intBankDetails.bankAccountType === IBankAccountType.INT ||
                intBankDetails.bankAccountType === IBankAccountType.INT_OTHER
                  ? intBankDetails.detailsOfCharges
                  : "",
              intBic:
                intBankDetails.bankAccountType === IBankAccountType.INT ||
                intBankDetails.bankAccountType === IBankAccountType.INT_OTHER
                  ? intBankDetails.intBic
                  : "",
              endDate:
                intBankDetails.bankAccountType === IBankAccountType.INT ||
                intBankDetails.bankAccountType === IBankAccountType.INT_OTHER
                  ? intBankDetails.endDate
                  : "",
            }}
            onToggle={() => {
              setIntModalVisible(false);
            }}
            requestClick={(type, payload) => {
              switch (type) {
                case IProfilePasswordModalAction.SAVE:
                  return handleAltSave({
                    ...payload,
                    id: intBankDetails.id,
                  });

                case IProfilePasswordModalAction.CANCEL:
                  return setIntModalVisible(false);

                default:
                  return;
              }
            }}
          />

          <BankDetailItemModal
            isVisible={isUkModalVisible}
            existingData={{
              accountNumber:
                ukBankDetails.bankAccountType === IBankAccountType.UK
                  ? ukBankDetails.bankAccount
                  : "",
              accountName:
                ukBankDetails.bankAccountType === IBankAccountType.UK
                  ? ukBankDetails.bankName
                  : "",
              startDate:
                ukBankDetails.bankAccountType === IBankAccountType.UK
                  ? ukBankDetails.startDate
                  : "",
              sortCode:
                ukBankDetails.bankAccountType === IBankAccountType.UK
                  ? ukBankDetails.bankCode
                  : "",
            }}
            onToggle={() => {
              setUkModalVisible(false);
            }}
            requestClick={(type, payload) => {
              switch (type) {
                case IProfilePasswordModalAction.SAVE:
                  return handleSave({
                    ...payload,
                    id: ukBankDetails.id,
                  });

                case IProfilePasswordModalAction.CANCEL:
                  return setUkModalVisible(false);

                default:
                  return;
              }
            }}
          />
        </div>
      </div>
    </div>
  );

  async function handleAltSave({
    id,
    toDate,
    accountName,
    iban,
    bic,
    detailsOfCharges,
    intBic,
    endDate,
  }: IBankDetailAltSaveItem) {
    if (!payee) {
      return;
    }

    setIsSendingUpdate(true);
    setIntModalVisible(false);

    try {
      await sendBankDetails({
        id,
        accountName: accountName,
        toDate,
        payee: payee.name,
        iban: iban,
        bic: bic,
        detailsOfCharges: detailsOfCharges,
        intBic: intBic,
        endDate: endDate,
        type: ISendBankDetailType.INT,
      });
    } catch (error) {
      setIsSendingUpdate(false);

      console.log(" err ", error);

      return dispatchStore(
        startAddNotification({
          title: "Error",
          description: "Unable to update bank details",
          type: INotificationType.ERROR,
        })
      );
    }

    setIsSendingUpdate(false);

    dispatchStore(
      startAddNotification({
        title: "Success",
        description: "Requested update was sent successfully",
        type: INotificationType.SUCCESS,
      })
    );
  }

  async function handleSave({
    id,
    accountName,
    accountNumber,
    sortCode,
    toDate,
  }: IBankDetailSavePayload) {
    if (!payee) {
      return;
    }

    setUkModalVisible(false);
    setIsSendingUpdate(true);

    try {
      await sendBankDetails({
        id,
        accountName,
        toDate,
        payee: payee.name,
        accountNumber,
        sortCode,
        type: ISendBankDetailType.UK,
      });
    } catch (error) {
      setIsSendingUpdate(false);

      return dispatchStore(
        startAddNotification({
          title: "Error",
          description: "Unable to update bank details",
          type: INotificationType.ERROR,
        })
      );
    }

    setIsSendingUpdate(false);

    dispatchStore(
      startAddNotification({
        title: "Success",
        description: "Requested update was sent successfully",
        type: INotificationType.SUCCESS,
      })
    );
  }
}
