import { useEffect, useMemo, useReducer } from "react";
import moment from "moment";
import { IBankDetailPreloadedDate, initialState, reducer } from "./state";
import BankDetailItemModalButtons from "./buttons/bank-detail-item-modal-buttons";
import Modal, { IModalType } from "../../../../components/ui/modal/Modal";
import { IProfilePasswordModalAction } from "../../../users/types";
import Input from "../../../../components/ui/inputs/Input";
import InputInlineError from "../../../../components/ui/inputs/inline-error/Input.inline-error";
import validateStringToNumber from "../../../../tools/common/validateStringToNumber";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import "./bank-detail-item-modal.scss";
import { NumberRegExError } from "../../../../tools/common/types";

export interface IRelatedInvoiceModal {
  isVisible: boolean;
  onToggle: (state: boolean) => void;
  existingData?: IBankDetailPreloadedDate;
  requestClick: (state: IProfilePasswordModalAction, payload?: any) => void;
}

export default function BankDetailItemModal({
  isVisible,
  onToggle,
  requestClick,
  existingData,
}: IRelatedInvoiceModal) {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  const currentDate = new Date();
  const maxDate = moment(
    new Date(currentDate.setMonth(currentDate.getMonth() - 12))
  );

  const isFormValid = useMemo(() => {
    return Object.values(state).every((item) => item.isValid);
  }, [state]);

  useEffect(() => {
    return () => {
      if (!isVisible) {
        dispatch({
          type: "resetState",
        });
      }
    };
  }, [isVisible]);

  useEffect(() => {
    if (existingData !== undefined && Object.keys(existingData).length > 0) {
      dispatch({
        type: "preloadState",
        payload: {
          ...existingData,
        },
      });
    }
  }, [existingData]);
  return (
    <Modal
      title="UK Bank Details"
      toggleVisibility={isVisible}
      onToggle={onToggle}
      type={IModalType.NOTIFY}
      customClass={"bank-detail-modal"}
      customBtns={
        <BankDetailItemModalButtons
          isLoading={false}
          isDisabled={!isFormValid}
          isUpdate={existingData && "id" in existingData}
          requestClick={(event) => {
            switch (event) {
              case IProfilePasswordModalAction.SAVE:
                return requestClick(IProfilePasswordModalAction.SAVE, {
                  toDate: (state.startDate.value as moment.Moment)
                    .format("DD/MM/YYYY")
                    .toString(),
                  accountName: state.accountName.value,
                  accountNumber: state.accountNumber.value,
                  sortCode: state.sortCode.value,
                });

              case IProfilePasswordModalAction.CANCEL:
                return requestClick(IProfilePasswordModalAction.CANCEL);

              default:
                return;
            }
          }}
        />
      }
    >
      <p>
        You can request an update on UK bank details which will be subject to
        validation from the CMS Team.
      </p>

      <div className="bank-detail-items">
        <Input
          id={state.accountNumber.id}
          placeholder={state.accountNumber.label}
          autocomplete={false}
          requestBlur={(event) => {
            if (state.accountNumber.dirty) {
              return;
            }

            return dispatch({
              type: "setInputTouched",
              payload: {
                ...state.accountNumber,
                dirty: true,
                value: event,
                isValid: event.length === 8,
                errorMessage:
                  event.length !== 8
                    ? "Account number should contain 8 chars"
                    : "",
              },
            });
          }}
          requestInput={(event) => {
            return dispatch({
              type: "setValue",
              payload: {
                ...state.accountNumber,
                dirty: true,
                value: event,
                isValid: event.length === 8,
                errorMessage:
                  event.length !== 8
                    ? "Account number should contain 8 chars"
                    : "",
              },
            });
          }}
          value={state.accountNumber.value as string}
          showLabel={true}
          error={state.accountNumber.dirty && !state.accountNumber.isValid}
        >
          <InputInlineError
            isVisible={
              state.accountNumber.dirty && !state.accountNumber.isValid
            }
            message={state.accountNumber.errorMessage || ""}
          />
        </Input>

        <Input
          id={state.accountName.id}
          placeholder={state.accountName.label}
          autocomplete={false}
          requestBlur={(event) => {
            if (state.accountName.dirty) {
              return;
            }

            return dispatch({
              type: "setInputTouched",
              payload: {
                ...state.accountName,
                dirty: true,
                value: event,
                isValid: event.length > 2 && event.length < 51,
                errorMessage:
                  event.length < 3 || event.length > 50
                    ? "Account name should contain at least 3 and max 50 chars"
                    : "",
              },
            });
          }}
          requestInput={(event) => {
            return dispatch({
              type: "setValue",
              payload: {
                ...state.accountName,
                dirty: true,
                value: event,
                isValid: event.length > 2 && event.length < 51,
                errorMessage:
                  event.length < 3 || event.length > 50
                    ? "Account name should contain at least 3 and max 50 chars"
                    : "",
              },
            });
          }}
          value={state.accountName.value as string}
          showLabel={true}
          error={state.accountName.dirty && !state.accountName.isValid}
        >
          <InputInlineError
            isVisible={state.accountName.dirty && !state.accountName.isValid}
            message={state.accountName.errorMessage || ""}
          />
        </Input>

        <Input
          id={state.sortCode.id}
          placeholder={state.sortCode.label}
          autocomplete={false}
          requestBlur={(event) => {
            if (state.sortCode.dirty) {
              return;
            }

            const validation = validateStringToNumber(event);

            return dispatch({
              type: "setInputTouched",
              payload: {
                ...state.sortCode,
                dirty: true,
                value: event,
                isValid:
                  event.length === 6 && validation === NumberRegExError.VALID,
                errorMessage:
                  event.length !== 6
                    ? "Sort Code should contain at least 3 chars"
                    : validation !== NumberRegExError.VALID
                    ? "Sort Code should be a number"
                    : "",
              },
            });
          }}
          requestInput={(event) => {
            const validation = validateStringToNumber(event);

            return dispatch({
              type: "setInputTouched",
              payload: {
                ...state.sortCode,
                dirty: true,
                value: event,
                isValid:
                  event.length === 6 && validation === NumberRegExError.VALID,
                errorMessage:
                  event.length !== 6
                    ? "Sort Code should contain at least 3 chars"
                    : validation !== NumberRegExError.VALID
                    ? "Sort Code should be a number"
                    : "",
              },
            });
          }}
          value={state.sortCode.value as string}
          showLabel={true}
          error={state.sortCode.dirty && !state.sortCode.isValid}
        >
          <InputInlineError
            isVisible={state.sortCode.dirty && !state.sortCode.isValid}
            message={state.sortCode.errorMessage || ""}
          />
        </Input>

        <div className="input input__label input__label--with-title input__label--datepicker">
          <span className="input__title">
            Effective Date for Bank Details Change
          </span>

          <DatePicker
            className="invoice-datepicker"
            desktopModeMediaQuery={"@media (min-width: 1px)"}
            inputFormat="DD/MM/YYYY"
            value={state.startDate.value || null}
            onOpen={async () => {
              if (state.startDate.dirty) {
                return;
              }

              dispatch({
                type: "setInputTouched",
                payload: {
                  ...state.startDate,
                  dirty: true,
                },
              });
            }}
            onChange={(event) => {
              dispatch({
                type: "setValue",
                payload: {
                  ...state.startDate,
                  value: event,
                  isValid: event !== null,
                  isOld: (event as moment.Moment).isBefore(maxDate),
                },
              });
            }}
            renderInput={(params: any) => <TextField {...params} />}
          />

          <InputInlineError
            customClass={
              state.startDate.isOld ? "input__inline-error--warning" : ""
            }
            isVisible={!state.startDate.isValid}
            message={
              !state.startDate.isValid && state.startDate.dirty
                ? "Effective Date for Bank Details Change not selected"
                : ""
            }
          />
        </div>
      </div>

      <strong>
        <small>
          *You will be notified via the email when this is completed.
        </small>
      </strong>
    </Modal>
  );
}
