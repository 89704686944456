import "./invoice-upload.scss";
import { Outlet, NavLink, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chevron-left.svg";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  isPayeeSelected,
  selectAvailablePayees,
  selectCurrentPayee,
  selectUserEmail,
  selectUserName,
  selectUserRole,
  selectUserVendorName,
} from "../../redux/user/selectors";
import Input from "../../components/ui/inputs/Input";
import { useEffect, useState } from "react";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import { useQueryCompanyAccess } from "../../redux/hooks/useQueryCompanyAccess";
import GenericNotFound from "../../components/ui/generic-not-found/GenericNotFound";
import InlineLoader from "../../components/ui/inline-loader/InlineLoader";
import { useQueryGlCodes } from "../../redux/hooks/useQueryGlCodes";
import { selectIsOverlayVisible } from "../../redux/core/selectors";
import { updateIsOverlayVisible } from "../../redux/core/core";
import { IUserRole } from "redux/user/user";

export default function InvoiceUpload() {
  const [useInfoCollapsed, setUserInfoCollapsed] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();

  const name = useAppSelector(selectUserName);
  const emailAddress = useAppSelector(selectUserEmail);
  const vendorName = useAppSelector(selectUserVendorName);

  const userRole = useAppSelector(selectUserRole);

  const nonAdminAvailablePayees = useAppSelector(selectAvailablePayees);

  const isPayeeSelectedState = useAppSelector(isPayeeSelected);

  const payee = useAppSelector(selectCurrentPayee);

  const currentUserCompany =
    userRole === IUserRole.admin
      ? payee?.companyName
      : nonAdminAvailablePayees && nonAdminAvailablePayees.length > 0
      ? nonAdminAvailablePayees[0].companyName
      : "N/A";

  const isOverlayVisible = useAppSelector(selectIsOverlayVisible);

  const {
    data: companyAccess,
    isRefetching: companyAccessRefetching,
    isRefetchError: companyAccessRefetchingError,
    isLoading: companyAccessLoading,
    error: companyAccessError,
    remove,
  } = useQueryCompanyAccess(
    userRole === IUserRole.admin && payee
      ? payee.companyId
      : nonAdminAvailablePayees && nonAdminAvailablePayees.length > 0
      ? nonAdminAvailablePayees[0].companyId
      : 0,
    payee !== undefined
  );
  //} = useQueryCompanyAccess(payee ? payee.companyId : 0, payee !== undefined);

  const { isFetching: isFetchingGlCodes } = useQueryGlCodes(
    false,
    payee ? payee.payeeNumber : ""
  );
  useEffect(() => {
    if (
      !isOverlayVisible &&
      companyAccess &&
      companyAccess.data &&
      companyAccess.data.data &&
      (location.pathname.includes("overhead") ||
        location.pathname.includes("cms-related"))
    ) {
      dispatch(updateIsOverlayVisible(true));
    }
  }, [
    companyAccess,
    companyAccess?.data.data,
    dispatch,
    isOverlayVisible,
    location,
    location.pathname,
  ]);

  useEffect(() => {
    if (!isPayeeSelectedState) {
      return navigate("/");
    }
  }, [isPayeeSelectedState, navigate]);

  useEffect(() => {
    return () => {
      remove();
    };
  }, [remove]);

  if (
    companyAccessError ||
    companyAccessRefetchingError ||
    !isPayeeSelectedState
  ) {
    return (
      <div className="invoice-upload d-flex justify-content-center f-wrap">
        <GenericNotFound hideLogo={true} />
      </div>
    );
  }

  return (
    <div className="invoice-upload d-flex justify-content-space-between f-wrap">
      <div className="invoice-upload__back-btn-wrapper">
        <button
          type="button"
          className="btn--reset invoice-upload__back-btn"
          onClick={() => navigate("/")}
        >
          <ChevronLeft width="13px" />
        </button>
      </div>

      <div className="invoice-upload__form-wrapper">
        <h1 className="invoice-upload__page-title">Billing Requirements</h1>
        {/* <p className="invoice-upload__intro">
          Please be advised that our client is responsible for payment of your
          bills, although we will facilitate the payment process. <br /> <br />{" "}
          Please note that our firm processes invoices based on a set billing
          cycle, so payment on your invoices will not be made immediately upon
          receipt. <br /> <br /> We ask you to submit your invoice within 45
          days of the date the work was performed. Invoices submitted after this
          term may not be honoured.
        </p> */}

        <p className="invoice-upload__intro">
          Invoices will be paid as per the terms of the contract with CMS CMNO
          LLP.
          <br /> <br />
          If an invoice relates to a CMS CMNO LLP client matter the terms may be
          linked to the CMS CMNO LLP billing cycle.
          <br /> <br />
          CMS may not accept invoices submitted 45 days after completion of the
          work performed/goods delivered.
        </p>

        <br />

        <div className="invoice__user-data">
          <h3 className="invoice__section-title">
            User Data
            <button
              type="button"
              className={`invoice__section-action btn--short btn btn--default ${
                useInfoCollapsed ? "invoice__section-action--collapsed" : ""
              }`}
              onClick={() => setUserInfoCollapsed(!useInfoCollapsed)}
            >
              <ChevronDown />
            </button>
          </h3>

          <div
            className={`invoice__section-user-content ${
              useInfoCollapsed ? "invoice__section-user-content--collapsed" : ""
            }`}
          >
            <Input
              id={"name"}
              placeholder={"Name"}
              requestInput={() => {}}
              value={name || ""}
              disabled={true}
              showLabel={true}
              error={false}
            />

            <Input
              id={"emailAddress"}
              placeholder={"Email Address"}
              requestInput={() => {}}
              value={emailAddress || ""}
              disabled={true}
              showLabel={true}
              error={false}
            />

            <Input
              id={"company"}
              placeholder={"Company"}
              requestInput={() => {}}
              value={currentUserCompany || ""}
              disabled={true}
              showLabel={true}
              error={false}
            />
          </div>
        </div>

        <h3 className="invoice__section-title">Select Category:</h3>

        <div className="invoice-upload__actions">
          {companyAccessLoading || companyAccessRefetching ? (
            <InlineLoader />
          ) : (
            <>
              {companyAccess?.data.data.includes("related") ? (
                <NavLink
                  to={"cms-related"}
                  onClick={(event) => {
                    if (
                      !location.pathname.includes("cms-related") ||
                      isFetchingGlCodes
                    ) {
                      dispatch(updateIsOverlayVisible(true));
                      return;
                    }
                    event.preventDefault();
                  }}
                  className={({ isActive }) =>
                    isActive
                      ? `inv-card invoice-upload__action--active invoice-upload__action ${
                          isFetchingGlCodes
                            ? "invoice-upload__action--disabled"
                            : ""
                        }`
                      : `inv-card invoice-upload__action ${
                          isFetchingGlCodes
                            ? "invoice-upload__action--disabled"
                            : ""
                        }`
                  }
                >
                  <span className="inv-card__container">
                    <span className="inv-card__title">CMS Client Related</span>

                    <span className="inv-card__content">
                      Work instructed by CMS to be performed on behalf of a CMS
                      client
                    </span>
                  </span>
                </NavLink>
              ) : null}

              {companyAccess?.data.data.includes("overhead") ? (
                <NavLink
                  to={"cms-overhead"}
                  onClick={(event) => {
                    if (!location.pathname.includes("cms-overhead")) {
                      dispatch(updateIsOverlayVisible(true));
                      return;
                    }
                    event.preventDefault();
                  }}
                  className={({ isActive = isOverlayVisible }) =>
                    isOverlayVisible
                      ? `inv-card invoice-upload__action--active invoice-upload__action ${
                          isFetchingGlCodes
                            ? "invoice-upload__action--disabled"
                            : ""
                        }`
                      : `inv-card invoice-upload__action ${
                          isFetchingGlCodes
                            ? "invoice-upload__action--disabled"
                            : ""
                        }`
                  }
                >
                  <span className="inv-card__container">
                    <span className="inv-card__title">CMS Overhead</span>

                    <span className="inv-card__content">
                      Work/services instructed by CMS to be performed on behalf
                      of CMS e.g., rent/stationery
                    </span>
                  </span>
                </NavLink>
              ) : null}

              {!companyAccess?.data.data.includes("overhead") &&
              !companyAccess?.data.data.includes("related") ? (
                <p className="no-available-data">
                  There is no available invoice type for the current company.
                </p>
              ) : null}
            </>
          )}
        </div>

        <div className="invoice-upload__content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
